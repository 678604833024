import React from "react"

// components
import Layout from "@modules/layout"
import Seo from "@modules/seo"

import Leistungen from "@modules/leistungen"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"

const IndexPage = ({ location, data }: any) => {
  const {
    outdoor_training_startbild_l,
    outdoor_training_startbild_m,
    outdoor_training_startbild_s,
    fyler,
    kundenzufriedenheit_icon,
    kostensenkung_icon,
    mitarbeitermotivation_icon,
    seilgartenelement_icon,
    fylerPDF,
    flossbau_icon,
    hoehle_icon,
    klettern_icon,
  } = data

  const outdoor_training_startbildL: any = getImage(
    outdoor_training_startbild_l
  )
  const outdoor_training_startbildM: any = getImage(
    outdoor_training_startbild_m
  )
  const outdoor_training_startbildS: any = getImage(
    outdoor_training_startbild_s
  )
  const fylerImg: any = getImage(fyler)

  return (
    <Layout location={location}>
      <Seo title="Outdoor-Training">
        <Leistungen
          sectionH1={
            <>
              <span>Outdoor-</span>
              <span>Training</span>
            </>
          }
          sectionH2="Lernen im hochwertigen Erlebnisraum."
          introImage={outdoor_training_startbildL}
          introImageM={outdoor_training_startbildM}
          introImageS={outdoor_training_startbildS}
          introImageAlt="test"
          intro={[
            {
              title: "Was wir unter Outdoor-Training verstehen",
              text: "Outdoor-Trainings sind besonders einprägsam und nachhaltig, da sie unmittelbar da ansetzen, wo Verhalten geprägt wird – in den ganz praktischen Erfahrungen. Gleichzeitig bieten  Herausforderungen in ungewohnten Situationen einen besonders guten „Nährboden“ für zieldienliche Erkenntnisse und Lernprozesse.",
            },
            {
              title: "Wie wir vorgehen",
              text: "Outdoor-Training bedeutet aktives, gehirngerechtes Lernen. In authentischen Situationen werden die Teilnehmer und Teilnehmerinnen mit Aufgaben konfrontiert, die das Erkennen und Verändern der eigenen Verhaltensmuster und des Handelns als Team ermöglichen. Die Transferphasen (moderierte Gruppenarbeit) nehmen ca. 50% der Trainingszeit in Anspruch. Der Ablauf der Maßnahme wird individuell auf die jeweilige Situation der Gruppe zugeschnitten.",
            },
          ]}
          supportTitle="Wir Unterstützen Sie"
          supportUl={[
            {
              icon: fylerImg,
              text: "Teamentwicklung",
            },
            {
              icon: fylerImg,
              text: "Selbstmanagement",
            },
            {
              icon: fylerImg,
              text: "Umgang mit Komplexität und Veränderung",
            },
            {
              icon: fylerImg,
              text: "Entwicklung von Führungskompetenzen",
            },
          ]}
          profitTitle="Wie sie davon profitieren"
          quoteActive
          quote='"Prioritäten richtig setzen – des Lebens größte Kunst."'
          quoteAuthor="- Stefan Rogal"
          profitUl={[
            {
              icon: kundenzufriedenheit_icon,
              iconAlt: "test",
              text: "Erhöhung der Kundenzufriedenheit",
              key: kundenzufriedenheit_icon.childImageSharp.id,
            },
            {
              icon: mitarbeitermotivation_icon,
              iconAlt: "test",
              text: "Steigende Mitarbeitermotivation",
              key: mitarbeitermotivation_icon.childImageSharp.id,
            },
            {
              icon: kostensenkung_icon,
              iconAlt: "test",
              text: "Kostensenkung",
              key: kostensenkung_icon.childImageSharp.id,
            },
          ]}
          feedback={[
            {
              text: (
                <p>
                  Christine Lehner und Heiko Thurner führen seit 2007 für die
                  Audi-Akademie das Outdoor-Seminar „Mein Verhalten im Team“
                  sowie das Seminar „Ich und mein Team – Handlungsspielräume
                  entdecken“ durch. Was ich in der Zusammenarbeit sehr schätze,
                  ist ihre konzeptionelle Stärke sowie gleichzeitig die ziel-
                  und ergebnisorientierte Klarheit in der Durchführung. Von
                  Seiten der Teilnehmer werden vor allem das Eingehen auf die
                  individuellen Fragen und der Praxisbezug hervorgehoben. Und:
                  Kreativität, Herzlichkeit, Humor und ständige
                  Verbesserungsideen zeigen, dass sie auch langjährige
                  Standardseminare immer aufs Neue mit viel „Herzblut“
                  durchführen.
                </p>
              ),
              author: "Judith Wagner",
              job: "Audi-Akademie Ingolstadt",
            },
          ]}
          recommendationTitle="Weitere Punkte unseres Leistungsspektrums"
          recommendataion={{
            outdoor: false,
            seminare_und_workshops: true,
            organisationsentwicklung: true,
            coaching: true,
          }}
          fylerActive
          fylerUrl={fylerPDF.publicURL}
          outdoorActive
          outdoorUl={[
            {
              icon: flossbau_icon,
              iconAlt: "test",
              text: "Flussüberquerung, Floßbau",
              key: flossbau_icon.childImageSharp.id,
            },
            {
              icon: hoehle_icon,
              iconAlt: "test",
              text: "Höhlentour",
              key: hoehle_icon.childImageSharp.id,
            },
            {
              icon: klettern_icon,
              iconAlt: "test",
              text: "Klettern, Abseilen",
              key: klettern_icon.childImageSharp.id,
            },
            {
              icon: seilgartenelement_icon,
              iconAlt: "test",
              text: "Seilgartenelemente",
              key: seilgartenelement_icon.childImageSharp.id,
            },
          ]}
        />
      </Seo>
    </Layout>
  )
}

export const query = graphql`
  query {
    outdoor_training_startbild_l: file(
      relativePath: { regex: "/outdoor_training_startbild_l/" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: CONSTRAINED)
        id
      }
    }
    outdoor_training_startbild_m: file(
      relativePath: { regex: "/outdoor_training_startbild_m/" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: CONSTRAINED)
        id
      }
    }
    outdoor_training_startbild_s: file(
      relativePath: { regex: "/outdoor_training_startbild_s/" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: CONSTRAINED)
        id
      }
    }
    fyler: file(relativePath: { regex: "/outdoor_flyer/" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: CONSTRAINED)
        id
      }
    }
    flossbau_icon: file(relativePath: { regex: "/flossbau_icon/" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: CONSTRAINED)
        id
      }
    }
    hoehle_icon: file(relativePath: { regex: "/hoehle_icon/" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: CONSTRAINED)
        id
      }
    }
    klettern_icon: file(relativePath: { regex: "/klettern_icon/" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: CONSTRAINED)
        id
      }
    }
    seilgartenelement_icon: file(
      relativePath: { regex: "/seilgartenelement_icon/" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: CONSTRAINED)
        id
      }
    }
    fylerPDF: file(
      absolutePath: {
        regex: "/ammerthaler_institut_organisationsentwicklung_flyer/"
      }
    ) {
      publicURL
    }
    kundenzufriedenheit_icon: file(
      relativePath: { regex: "/kundenzufriedenheit_icon/" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: CONSTRAINED)
        id
      }
    }
    kostensenkung_icon: file(relativePath: { regex: "/kostensenkung_icon/" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: CONSTRAINED)
        id
      }
    }
    mitarbeitermotivation_icon: file(
      relativePath: { regex: "/mitarbeitermotivation_icon/" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: CONSTRAINED)
        id
      }
    }
  }
`

export default IndexPage
